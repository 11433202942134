.top-secret {
  min-height: 100vh;
  background-image: url(./secrets-loginBG.png);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.top-secret__sub-container {
  margin-top: 75px;
  color: white;
  display: flex;
  flex-direction: column;
}

.top-secret__sub-container button {
  box-shadow: inset 0px -3px 7px 0px #29bbff;
  background: linear-gradient(to bottom, #2dabf9 5%, #0688fa 100%);
  background-color: #2dabf9;
  border-radius: 8px;
  border: 1px solid #0b0e07;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 40px;
  padding: 9px 23px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #263666;
  margin-bottom: 25px;
}

.top-secret__sub-container button:hover {
  background: linear-gradient(to bottom, #0688fa 5%, #2dabf9 100%);
  background-color: #0688fa;
}

.top-secret__sub-container button:active {
      position: relative;
  top: 1px;
}

.top-secret__sub-container button:focus {
    outline: none;
}

.top-secret__doug,
.top-secret__video-owner {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-secret__video-owner {
  grid-column: 3;
}

.top-secret__doug h2,
.top-secret__video-owner h2 {
  font-size: 2.5rem;
}

@media screen and (min-width: 700px) {
  .top-secret {
  min-height: 100vh;
  background-image: url(./secrets-loginBG.png);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.top-secret__sub-container {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}
}