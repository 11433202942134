.season-two {
  min-height: 100vh;
  background-image: url(./RotTMNT\ BG2.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.season-two__sub-container {
    margin-top: 90px;
}