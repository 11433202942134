.secrets-login {
  min-height: 100vh;
  background-image: url(./enter-password-pc.png);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.secrets-login__main-container {
  padding: 5% 5%;
}

.secrets-login__sub-container {
  color: white;
  display: flex;
  flex-direction: column;
  background-color: purple;
  padding: 40px;
  margin-bottom: 200px;
  border-radius: 15px;
  font-family: "Courier New", Courier, monospace;
}

.secrets-login__sub-container h1 {
  margin-top: 0;
}

.secrets-login__sub-container input {
  padding: 9px;
  font-size: 20px;
  border-width: 2px;
  border-color: #cccccc;
  background-color: #ffffff;
  color: #000000;
  border-style: solid;
  border-radius: 0px;
  box-shadow: 5px 5px 5px rgba(66, 66, 66, 0.75);
  font-family: "Courier New", Courier, monospace;
}

.secrets-login__sub-container input:active {
  outline: none;
}

.secrets-login__button {
  margin-top: 25px;
  box-shadow: inset 0px -3px 7px 0px #29bbff;
  background: linear-gradient(to bottom, #2dabf9 5%, #0688fa 100%);
  background-color: #2dabf9;
  border-radius: 3px;
  border: 1px solid #0b0e07;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 15px;
  padding: 9px 23px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #263666;
}

.secrets-login__button:hover {
  background: linear-gradient(to bottom, #0688fa 5%, #2dabf9 100%);
  background-color: #0688fa;
}

.secrets-login__button:active {
  position: relative;
  top: 1px;
}

.secrets-login__button:focus {
    outline: none;
}