.shorts {
  min-height: 100vh;
  background-image: url(./shortsBG2.jpeg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.shorts__sub-container {
    margin-top: 90px;
}