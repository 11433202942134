.season-select {
  min-height: 100vh;
  background-image: url(./unleash-the-flavor.png);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.season-select__main-container {
  grid-column: 3;
  align-self: center;
}

.season-select__sub-container {
  display: flex;
  flex-direction: column;
  color: whitesmoke;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.season-select__sub-container h1 {
  font-size: 2rem;
}

.season-select__sub-container button {
  box-shadow: 0px 1px 0px 0px #fff6af;
  background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
  background-color: #ffec64;
  border-radius: 6px;
  border: 1px solid #ffaa22;
  display: inline-block;
  cursor: pointer;
  color: #333333;
  font-family: Impact;
  font-size: 28px;
  font-weight: bold;
  padding: 10px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffee66;
  margin-bottom: 25px;
}

.season-select__sub-container button:hover {
  background: linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
  background-color: #ffab23;
}

.season-select__sub-container button:active {
  position: relative;
  top: 1px;
}

.season-select__sub-container button:focus {
  outline: none;
}
