.home {
  min-height: 100vh;
  background-image: url(./punch-chowder-masterBG-sm.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.home__main-container {
  width: 100%;
}

.home__sub-container {
  padding: 5% 5%;
  border-radius: 20px;
}

.home__sub-container button {
	box-shadow: 0px 1px 0px 0px #fff6af;
	background:linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
	background-color:#ffec64;
	border-radius:6px;
	border:1px solid #ffaa22;
	display:inline-block;
	cursor:pointer;
	color:#333333;
	font-family:Impact;
	font-size:28px;
	font-weight:bold;
	padding:10px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffee66;
}

.home__sub-container button:hover {
	background:linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
	background-color:#ffab23;
}

.home__sub-container button:active {
	position:relative;
	top:1px;
}

.home__sub-container button:focus {
  outline: none;
}

.home__logo {
  width: 85vw;
}