.bonus-button {
  box-shadow: 7px 4px 9px -3px #e184f3;
  background: linear-gradient(to bottom, #c123de 5%, #a20dbd 100%);
  background-color: #c123de;
  border-radius: 6px;
  border: 1px solid #a511c0;
  display: inline-block;
  cursor: pointer;
  color: lightsalmon;
  font-family: Impact;
  font-size: 25px;
  font-weight: bold;
  padding: 10px 25px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #9b14b3;
  margin-bottom: 25px;
}

.bonus-button:hover {
  background: linear-gradient(to bottom, #a20dbd 5%, #c123de 100%);
  background-color: #a20dbd;
}

.bonus-button:active {
  position: relative;
  top: 1px;
}

.bonus-button:focus {
    outline: none;
}